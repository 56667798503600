import React from "react";
import "./Background.styles.scss";

function PromoBackground({ children }) {
    return (
        <div>
            <div className="content-layer">
                <div className="background-layer">
                    <div className="layer-1 hero-image" />
                    <div className="layer-2 promo-vector-1" />
                    <div className="layer-2 promo-vector-2" />
                    <div className="layer-2 promo-vector-3" />
                </div>
                {children}
            </div>
        </div>
    );
}

export default PromoBackground;
