import React from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { AboutCard } from "../components/Cards";
import NavBar from "../components/NavBar";
import Button from "../components/Button";
import { Form, SubmitButton, TextField } from "../components/FormElements";
import ArtistDetails from "../components/ArtistDetails/ArtistDetails";
import { Background } from "../components/Background";

const registerSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Required"),
});

const HomePage = () => {
    const [registerFormData, setRegisterFormData] = React.useState({
        email: "",
    });

    const onSubmitRegisterForm = (registeredData) => {};

    return (
        <Background>
            <div className="landing-page-content">
                <NavBar showLinks={true} />
                <section className="content-section vh-100">
                    <div className="container">
                        <div className="row w-100 d-flex align-items-center mx-0">
                            <div className="col-md-8">
                                <div className="heading-container text-center">
                                    <div className="heading-xl  font-weight-bold">
                                        Making NFTs accessible to everyone.
                                    </div>
                                    <div className="heading-root mt-4">
                                        Hyperglade makes the process of buying,
                                        selling and
                                    </div>
                                    <div className="heading-root">
                                        minting your NFTs, easier, faster and
                                        more accessible.
                                    </div>
                                    <div className="d-block px-auto text-center mt-4">
                                        <Button
                                            title="REGISTER"
                                            onClick={() => {
                                                window.location = "/#register";
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 col-lg-3">
                                <div className="artist-details w-100">
                                    <div className="content content-card">
                                        <div className="artist-card-container">
                                            <div className="artist-card-ghost" />
                                            <img
                                                alt="Artist avatar"
                                                src="/assets/img/nft/nft1.png"
                                                className="artist-card"
                                                style={{ height: "auto" }}
                                            />
                                            <img
                                                alt="Artist card thumbnail"
                                                src="/assets/img/nft/nft2.jpg"
                                                className="artist-card-thumb thumb-1"
                                            />
                                            {/* <img
                                        alt="Artist card thumbnail"
                                        src="/assets/img/nft/nft3.jpg"
                                        className="artist-card-thumb thumb-2"
                                    /> */}
                                            <img
                                                alt="Artist card thumbnail"
                                                src="/assets/img/nft/nft4.jpg"
                                                className="artist-card-thumb thumb-3"
                                            />
                                            <img
                                                alt="Artist card thumbnail"
                                                src="/assets/img/nft/nft5.jpg"
                                                className="artist-card-thumb thumb-3"
                                            />
                                            <img
                                                alt="Artist card thumbnail"
                                                src="/assets/img/nft/nft6.jpg"
                                                className="artist-card-thumb thumb-3"
                                            />
                                            <img
                                                alt="Artist card thumbnail"
                                                src="/assets/img/nft/nft7.png"
                                                className="artist-card-thumb thumb-3"
                                            />
                                            {/* <img
                                        alt="Artist card thumbnail"
                                        src="/assets/img/nft/nft8.jpg"
                                        className="artist-card-thumb thumb-3"
                                    /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4">
                                    <div className="artist-details w-100">
                                        <div className="content content-card">
                                            <div className="artist-card-container">
                                                <div className="artist-card-ghost" />
                                                <img
                                                    alt="Artist avatar"
                                                    src="/assets/img/artist-image.png"
                                                    className="artist-card"
                                                />
                                                <img
                                                    alt="Artist card thumbnail"
                                                    src="/assets/img/artist-thumb-1.png"
                                                    className="artist-card-thumb thumb-1"
                                                />
                                                <img
                                                    alt="Artist card thumbnail"
                                                    src="/assets/img/artist-thumb-2.png"
                                                    className="artist-card-thumb thumb-2"
                                                />
                                                <img
                                                    alt="Artist card thumbnail"
                                                    src="/assets/img/artist-thumb-3.png"
                                                    className="artist-card-thumb thumb-3"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content-section">
                    <div className="heading-container text-center py-2">
                        <div className="heading-lg font-weight-bold">
                            Why Hyperglade?
                        </div>
                        <div className="heading-xs mt-3">
                            Our technology allows you to buy/sell NFT using
                            cryptocurrency or your credit card.
                        </div>
                    </div>
                    <div className="cards-container py-5">
                        <AboutCard
                            icon="/assets/icon/icon-digital-collections.svg"
                            title="Currency Agnostic"
                            subtitle="Mint, Sell and Buy NFTs using either cryptocurrency or your credit card. It’s that simple!"
                        />
                        <AboutCard
                            icon="/assets/icon/icon-digital-collections-2.svg"
                            title="Host your brand auctions"
                            subtitle="We can tailor make your NFT launchpad, allowing you to get the most out of your brand’s NFT launch."
                        />
                        <AboutCard
                            icon="/assets/icon/icon-digital-collections-3.svg"
                            title="Classic NFT / IP-NFT"
                            subtitle="We have a brand new type of NFT! If you wish to transfer your IP rights along with the NFT, now you can do that."
                        />
                    </div>
                </section>
                <section
                    className="container content-section padded-y pb-2"
                    id="register"
                >
                    <div className="heading-container text-center py-2">
                        <div className="heading-lg font-weight-bold">
                            Register Now
                        </div>
                        <div className="heading-xs px-0 mt-3">
                            Please use your working email address so we can
                            connect you with the Hyperglade
                        </div>
                    </div>
                    </section>
                    <div className="container">
                    <div className="form-row justify-content-center align-items-center mt-4">
                        <div className="col-xl-6 col-xxl-6">
                            <div className="register-form-container pt-2 w-100">
                                <Form
                                    initialValues={registerFormData}
                                    validationSchema={registerSchema}
                                    onSubmit={onSubmitRegisterForm}
                                    enableReinitialize
                                    className="register-form d-md-flex"
                                >
                                    <div className="col-12 col-md-8 px-2">
                                        <TextField
                                            name="email"
                                            placeholder="email"
                                        />
                                    </div>
                                    <div className="col-12 col-md-4 px-2 mt-3 mt-md-0  text-center text-md-none">
                                        <SubmitButton title="REGISTER" />
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                    </div>
                    
                
                <section className="content-section mt-5 pt-md-5">
                    <div className="container">
                        <div className="row bid-step-details">
                            <div className="col-12 col-md-6 ">
                                <div className="content content-text">
                                    <div className="title">
                                        Pay with your Card or Crypto
                                    </div>
                                    <div className="subtitle">
                                        We believe that the purchase of NFT
                                        should be as easy as shopping online.
                                        The method of payment should not be a
                                        barrier to entry, specially from
                                        emerging markets that has restrictions
                                        on cryptocurrency based payments. Our
                                        technology allows you to make the
                                        payment using either cryptocurrency or
                                        fiat(Using credit cards) currency.
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-6 col-xl-4 col-xxl-6">
                                <div className="content content-card">
                                    <div className="bid-card-container rotated-cw">
                                        <div className="bid-card bid-card-bg step-1">
                                            <div className="badge-card" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-xl-5">
                        <div className="row bid-step-details">
                            <div className="col-12 col-md-5 col-lg-5 col-xl-4 col-xxl-6">
                                <div className="content content-card">
                                    <div className="bid-card-container rotated-ccw">
                                        <div className="bid-card bid-card-bg step-2">
                                            <div className="badge-card" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 offset-md-1 offset-xxl-0">
                                <div className="content content-text">
                                    <div className="step-text my-0">
                                        Introducing
                                    </div>
                                    <div className="title">IP-NFT</div>
                                    <div className="subtitle">
                                        Classic NFT does not necessarily allow
                                        the buyer of the creation, to monetize
                                        it. Intellectual property rights need to
                                        be transferred sepeately in order for
                                        the buyer to commercialize the creation.
                                        However, with IP-NFT, the holder of the
                                        NFT receives all commercial rights to
                                        the creation, and when you sell the NFT,
                                        those rights are passed on to the next
                                        holder of the NFT.
                                    </div>
                                    <div className="d-block px-auto mt-3">
                                        <Button
                                            title="READ WHITEPAPER"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container mt-xl-5">
                        <div className="row bid-step-details">
                            <div className="col-12 col-md-6 ">
                                <div className="content content-text">
                                    <div className="step-text my-0">
                                        Tailor made
                                    </div>
                                    <div className="title">Brand Auctions</div>
                                    <div className="subtitle">
                                        Is your brand looking to release an NFT
                                        collection? We tailor make your brand’s
                                        NFT launchpad, consult on the NFT
                                        release and provide the technology
                                        required.
                                    </div>
                                    <div className="d-block px-auto mt-2">
                                        <Button title="LEARN MORE" disabled />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-5 col-lg-6 col-xl-4 col-xxl-6">
                                <div className="content content-card">
                                    <div className="bid-card-container rotated-cw">
                                        <div className="bid-card bid-card-bg step-3">
                                            <div className="badge-card" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="footer mt-5">
                    <div className="ribbon" />
                    <div className="footer-content">
                        <div className="org-info">
                            <div className="logo-container">
                                <div className="logo-wrapper">
                                    <img
                                        src="/assets/img/logo-light.svg"
                                        alt=""
                                        className="logo"
                                    />
                                </div>
                                <div className="logo-text">
                                    <div className="title">Hyperglade</div>
                                    <div className="subtitle">NFT Drop</div>
                                </div>
                            </div>
                            <div className="copyright-info">
                                © 2021 Hyperglade Auction. All rights reserved.
                            </div>
                            <div className="social-buttons">
                                <a
                                    href="https://twitter.com/hyperglade"
                                    target="_blank"
                                    className="social-button"
                                    type="button"
                                >
                                    <img
                                        src="/assets/icon/twitter.png"
                                        width="100%"
                                        alt=""
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/hyper.glade"
                                    target="_blank"
                                    className="social-button"
                                    type="button"
                                >
                                    <img
                                        src="/assets/icon/instagram.png"
                                        width="100%"
                                        alt=""
                                    />
                                </a>
                                <a
                                    href="https://discord.gg/m8AsMPRX"
                                    target="_blank"
                                    className="social-button"
                                    type="button"
                                >
                                    <img
                                        src="/assets/icon/discord.png"
                                        width="100%"
                                        alt=""
                                    />
                                </a>
                                <a
                                    href="https://www.facebook.com/HyperGlade-103943078730431/"
                                    target="_blank"
                                    className="social-button"
                                    type="button"
                                >
                                    <img
                                        src="/assets/icon/facebook.png"
                                        width="100%"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="more-info">
                            <div className="site-info">
                                <div className="title">HYPERGLADE</div>
                                <Link className="link" to="/">
                                    Home
                                </Link>
                                <Link className="link" to="/app/who-we-are">
                                    Who we are
                                </Link>
                            </div>
                            <div className="policy-info">
                                <div className="title">INFORMATION</div>
                                <Link className="link" to="/">
                                    About Us
                                </Link>
                                <Link className="link" to="/">
                                    Contact Us
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Background>
    );
};

export default HomePage;
