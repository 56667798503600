import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PublicRoute } from "./CustomRoutes";
import ModalContainer from "../components/ModalContainer";
import LandingPage from "../pages/LandingPage";
import PromotionPage from "../pages/PromotionPage";

const Routes = () => {
    return (
        <Router>
            <div className="App">
                <Switch>
                    <PublicRoute restricted component={PromotionPage} path="/" exact />
                    <PublicRoute restricted component={PromotionPage} path="/auction" exact />
                </Switch>
                <ModalContainer />
            </div>
        </Router>
    );
};

export default Routes;
