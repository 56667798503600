import React from "react";
import "./Button.styles.scss";

const Button = ({ onClick, title, disabled, className }) => (
    <button type="button" className={`button w-gradient ${className}`} onClick={onClick} disabled={disabled}>
        {title || ""}
        <div className="button-glow" />
    </button>
);

export default Button;
