import React from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "bootstrap/js/src/collapse";
import Button from "../../components/Button";
import "./NavBar.styles.scss";

const navItems = [
    { path: "/auction", Name: "AUCTION", disabled: false },
    { path: "", Name: "ABOUT", disabled: true },
];

const NavBar = ({ showLinks = true, promoLogo }) => {
    const { pathname } = useLocation();
    const history = useHistory();

    const isActive = (item) => {
        if (pathname === item?.path) {
            return "active";
        }
        return "";
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark">
            <Link className="navbar-brand" to="/">
                <div className="logo-container">
                    <div className="logo-wrapper">
                        <img
                            src="/assets/img/logo-light.svg"
                            alt=""
                            className="logo"
                        />
                    </div>
                    <div className="logo-text">
                        <div className="title">Hyperglade</div>
                        <div className="subtitle">NFT Drop</div>
                    </div>
                </div>
            </Link>
            {promoLogo && (
                <div className="promo-logo">
                    <img
                        src="/assets/promo-background/img/promo-logo.svg"
                        alt=""
                    />
                </div>
            )}
            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navItems"
                aria-controls="navItems"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon" />
            </button>
            {showLinks && (
                <div
                    className="collapse navbar-collapse justify-content-end"
                    id="navItems"
                >
                    <div className="navbar-nav">
                        {navItems.map((item) => (
                            <Link
                                key={item.path}
                                className={`nav-item nav-link mr-3 ${isActive(
                                    item
                                )}`}
                                to={`${item.path}`}
                                disabled={item.disabled}
                            >
                                {item.Name}
                            </Link>
                        ))}
                        <Button
                            className="ml-md-3"
                            onClick={() => {
                                window.location = "/#register";
                            }}
                            title="JOIN"
                        />
                    </div>
                </div>
            )}
        </nav>
    );
};

export default NavBar;
