import React from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ArtCard } from "../Cards";

const cardInformation = [
    {
        id: 1,
        title: "Race is something",
        bid: "4.56",
        thumbnail: "/assets/img/card-img-1.png",
        badge: "/assets/icon/card-badge-1.svg",
    },
    {
        id: 2,
        title: "Doted Meditation",
        bid: "3.22",
        thumbnail: "/assets/img/card-img-2.png",
        badge: "/assets/icon/card-badge-2.svg",
    },
    {
        id: 3,
        title: "Mind Mist",
        bid: "1.33",
        thumbnail: "/assets/img/card-img-3.png",
        badge: "/assets/icon/card-badge-3.svg",
    },
    {
        id: 4,
        title: "Psychedelic Blue Fusion",
        bid: "2.22",
        thumbnail: "/assets/img/card-img-4.png",
        badge: "/assets/icon/card-badge-4.svg",
    },
    {
        id: 5,
        title: "Fiber World",
        bid: "5.55",
        thumbnail: "/assets/img/card-img-5.png",
        badge: "/assets/icon/card-badge-5.svg",
    },
    {
        id: 6,
        title: "Bluematic Frozen View",
        bid: "3.33",
        thumbnail: "/assets/img/card-img-6.png",
        badge: "/assets/icon/card-badge-6.svg",
    },
];

const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 1599,
            settings: {
                slidesToShow: 3,
            },
        },
        {
            breakpoint: 1199,
            settings: {
                slidesToShow: 2,
            },
        },

        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
            },
        },
    ],
};

function Carousel({ children }) {
    return (
        <Slider {...settings}>
            {cardInformation.map((card) => (
                <div key={card.id} className="art-card mt-5 mx-auto">
                    <ArtCard
                        thumbnail={card.thumbnail}
                        badge={card.badge}
                        cardTitle={card.title}
                        moneyrateCard={card.bid ? `${card.bid} ETH` : ""}
                        cardSubtitle="Current Bid"
                        btnTitle="BID NOW"
                    />
                </div>
            ))}
        </Slider>
    );
}

export default Carousel;
