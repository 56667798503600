import React, { createContext, useEffect } from 'react';
import fb from 'firebase';
import app from 'firebase/app';
import 'firebase/database';
import 'firebase/storage';
import 'firebase/firestore';
import 'firebase/auth';
import { getFirebaseConfig } from '../config';

const FirebaseContext = createContext(null)
export { FirebaseContext }

export default ({ children }) => {
    let firebase = {
        app: null,
        database: null,
        storage: null
    }

    // check if firebase app has been initialized previosly
    // if not, initialize with the config we saved earlier
    if (!app.apps.length) {
        app.initializeApp(getFirebaseConfig());
        firebase = {
            app: app,
            database: app.firestore(),
            storage: app.storage(),
            auth: app.auth(),

            api: {
                createRegistration
            }
        };

        function createRegistration(registrationData){
            return new Promise((resolve, reject) => {
                firebase.database.collection("prereg").add({
                    ...registrationData,
                    createdAt: (new Date()).getTime()
                })
                .then((doc) => {
                    resolve({
                        id: doc.id
                    });
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                })
            })
            
        }

    }


    return (
        <FirebaseContext.Provider value={firebase}>
            {children}
        </FirebaseContext.Provider>
    )
}