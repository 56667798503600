 import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import { Provider } from 'react-redux';
import './App.scss';
import './custom.scss'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import store from './state/store';
import Routes from "./routes/Routes";
import FirebaseProvider from "./firebase/FirebaseContext";

function App() {
	return (
		<Provider store={store}>
			<ReduxToastr
				timeOut={2000}
				transitionIn='fadeIn'
				transitionOut='fadeOut'
				closeOnToastrClick
			/>
			<FirebaseProvider>
				<Routes />
			</FirebaseProvider>
		</Provider>
	);
}

export default App;
