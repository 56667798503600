import React, { useEffect, useState } from "react";
import "./Timer.styles.scss";

function Timer({ scheduleDate }) {
    const calculateTimeLeft = () => {
        return {
            Days: "-",
            Hours: "-",
            Minutes: "-",
            Seconds: "-"
        }

        let year = new Date().getFullYear();
        const difference = +scheduleDate - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                Days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                Hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                Minutes: Math.floor((difference / 1000 / 60) % 60),
                Seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    const [year] = useState(new Date().getFullYear());

    useEffect(() => {
        setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
    });

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        timerComponents.push(
            <div className="">
                <div className="interval">{interval}</div>
                <div className="times-wrapper">{timeLeft[interval]} </div>
            </div>
        );
    });

    return (
        <div className="timer-countdown d-flex align-items-center justify-content-between">
            {timerComponents.length ? timerComponents : <span>Time's up!</span>}
        </div>
    );
}

export default Timer;
