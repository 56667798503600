import React, { useContext } from "react";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { AboutCard } from "../components/Cards";
import NavBar from "../components/NavBar";
import Timer from "../components/Timer/Timer";
import Button from "../components/Button";
import { Form, SubmitButton, TextField } from "../components/FormElements";
import ArtistDetails from "../components/ArtistDetails/ArtistDetails";
import { Background } from "../components/Background";
import { FirebaseContext } from "../firebase/FirebaseContext";
import PromoBackground from "../components/Background/PromoBackground";
import Carousel from "../components/Carousel/Carousel";

const registerSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email"),
});

function PromotionPage() {
    const { api } = useContext(FirebaseContext);
    const [registerFormData, setRegisterFormData] = React.useState({
        email: "",
    });

    const onSubmitRegisterForm = async (
        registeredData,
        { setSubmitting, resetForm, setStatus }
    ) => {
        try {
            const result = await api.createRegistration(registeredData);
            console.log(result);
            setRegisterFormData({
                email: "",
            });
            resetForm();
        } catch (e) {
            console.log(e);
        }
    };
    return (
        <PromoBackground>
            <div className="landing-page-content">
                <NavBar showLinks={true} promoLogo />
                <section className="hero-wrapper">
                    <div className="d-flex align-items-end align-items-xl-center hero-inner">
                        <div className="container">
                            <div className="row w-100 d-flex align-items-center justify-content-center mx-0">
                                <div className="col-md-8">
                                    <div className="heading-container text-center">
                                        <div
                                            className="heading-xs letter-space-2"
                                            style={{ opacity: 0.8 }}
                                        >
                                            NEW RELEASE AUCTIONS
                                        </div>
                                        <div style={{ marginTop: "-48px" }}>
                                            <img
                                                src="/assets/promo-background/img/promo-logo-2.svg"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mx-0 justify-content-center countdown-row">
                        <div className="col-md-6 col-xl-4 col-xxl-3">
                            <Timer scheduleDate={new Date(2022, 3, 2, 18, 0, 0, 0)} /> {/* months starts from 0, 3 means April  */}
                        </div>
                    </div>
                    <div className="bottom-text text-center mt-2">
                        <div className="heading-root letter-space-2">
                            Stay tuned for Sri Lanka’s First Music Auction
                        </div>
                        <button
                            type="button"
                            className="scroll-down-indicator mt-3"
                        >
                            ⌄
                        </button>
                    </div>
                </section>
                {/* <section className="content-section">
                    <div className="heading-container py-2">
                        
                        <div className="heading-xs">
                            Our technology allows you to buy/sell NFT using
                            cryptocurrency or your credit card.
                        </div>
                    </div>
                    
                </section> */}
                <div className="container mt-5 bid-details-wrapper">
                    <div className="text-center heading-container">
                        <div>
                            <img
                                src="/assets/promo-background/img/place-auction.png"
                                alt=""
                            />
                        </div>
                        <div className="heading-lg letter-space-1 font-weight-bold mt-3">
                            PEMKEKULA AUCTION
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-xxl-6">
                                <div className="heading-xs letter-space-1 mt-3">
                                    The single ‘Pem Kekula’ and its music video
                                    took over by storm, creating its own riot on
                                    TikTok, YouTube and all over the Lankan
                                    social mediasphere.
                                </div>
                                <div className="heading-xs letter-space-1 mt-3">
                                    From plenty of reaction videos to becoming a
                                    radio hit nationally, to a must have party
                                    anthem & a wedding staple, this single is
                                    now ready to become the next best version of
                                    itself- A Music Non Fungible Token!
                                </div>
                            </div>
                        </div>

                        {/* <div className="row justify-content-center mt-5">
                            <div className="col-xl-5">
                                <div className="current-bid heading-container">
                                    <div>
                                        <div className="text-left">
                                            Current Bid
                                        </div>
                                        <div
                                            className="font-weight-light heading-xs mt-1"
                                            style={{ opacity: "0.6" }}
                                        >
                                            (Started Bid : 0.99 ETH)
                                        </div>
                                    </div>
                                    <div className="heading-lg font-weight-bold">
                                        2.22 ETH
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <div className="row justify-content-center mt-5">
                            <div className="col-xl-5 px-xl-5">
                                <div
                                    className="heading-xs letter-space-2 font-weight-light"
                                    style={{ opacity: "0.6" }}
                                >
                                    PLACE THE NEXT BID
                                </div>
                                <div className="d-flex align-items-center justify-content-between mt-4">
                                    <div>
                                        <div className="text-left">
                                            Next Bid
                                        </div>
                                        <div className="heading-xl font-weight-bold">
                                            3.33 ETH
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            title="BID NOW"
                                            className="py-3"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>

                <div className="container mt-5">
                    <div className="text-center heading-container">
                        <img
                            src="/assets/promo-background/img/promo-about.svg"
                            alt=""
                            className="img-fluid"
                        />
                    </div>

                    <div className="row align-items-center justify-content-center heading-container">
                        <div className="col-xl-10 col-xxl-8">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-md-6 col-xl-4 text-center">
                                    <img
                                        src="/assets/promo-background/img/img-romaine.jpg"
                                        alt=""
                                        className="img-fluid "
                                    />
                                    <div className="">ROMAINE WILLIS</div>
                                </div>
                                <div className="col-md-6 col-xl-4 text-center">
                                    <img
                                        src="/assets/promo-background/img/img-mass.jpg"
                                        alt=""
                                        className="img-fluid"
                                    />
                                    <div className="">DJ MASS</div>
                                </div>
                                <div className="col-md-6 col-xl-4 text-center">
                                    <img
                                        src="/assets/promo-background/img/img-apzi.jpg"
                                        alt=""
                                        className="img-fluid"
                                    />
                                    <div className="">APZI</div>
                                </div>
                            </div>
                            <div className="text-center mt-4">
                                Pem Kekula NFT features 5 unique NFTs from the
                                hit song by DJ Mass, Apzi & Romaine Willis.
                            </div>
                            <div className="text-center mt-3">
                                ‘Pem Kekula’ is Sri Lanka’s first music NFT auction, and there will only be 5 for grabs. The auction is currently on hold due to the prevailing situation in Sri Lanka. Register for updates here. Bidders will be able to pay by credit or debit card, in addition to the cryptocurrency option. Apart from the NFT, everyone who joins in will get a souvenir NFT.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container mt-5 pt-3 pt-xl-5">
                    <div className="text-center heading-container">
                        <div className="heading-lg letter-space-1 font-weight-bold">
                            NFT REVEAL{" "}
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-xxl-6">
                                <div className="heading-xs letter-space-1 mt-4">
                                    Pem Kekula NFT drop features iconic NFTs
                                    from the hit song Pem Kekula. In addition to
                                    the NFTs, the auction also provides amazing
                                    souvenirs and gifts.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container pb-5" id="register">
                    <div className="form-row justify-content-center align-items-center mt-3">
                        <div className="col-12 text-center">
                            <a 
                                className="btn w-gradient submit-btn"
                                href="https://auctions.hyperglade.com"
                                target="_blank"
                                style={{ width: "230px" }}
                            >VIEW REVEALED NFTS</a>
                        </div>
                    </div>
                </div>

                <section className="content-section">
                    <div className="container">
                        <div className="row bid-step-details justify-content-center align-items-start mb-5">
                            <div className="col-12 col-md-6 col-xl-5 pr-xl-5">
                                <div className="promo-images mb-3">
                                    <img
                                        src="/assets/promo-background/img/poster1.png"
                                        alt=""
                                        className="img-left img-fluid"
                                    />
                                    <img
                                        src="/assets/promo-background/img/rect1.svg"
                                        alt=""
                                        className="img-right img-fluid"
                                    />
                                </div>
                                <div className="content content-text">
                                    <div className="title mb-0">WHAT IS</div>
                                    <div className="title">PEM KEKULA NFT?</div>
                                    <div className="subtitle">
                                        Pem Kekula NFT features 5 unique NFTs
                                        from the hit song by DJ Mass, Apzi &
                                        Romaine Willis. The single packs in a
                                        killer rap by female powerhouse Apzi,
                                        with Romaine Willis recreating those
                                        nostalgic lines from the iconic movie
                                        song ‘Pem Kekula Pipi’(by the late Mr.
                                        Ananda Samarakoon), and Dj Mass bringing
                                        in the heat with an epic production.
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-5  pl-xl-5 mt-5 mt-md-0">
                                <div className="promo-images mb-3">
                                    <img
                                        src="/assets/promo-background/img/poster2.png"
                                        alt=""
                                        className="img-left img-fluid"
                                    />
                                    <img
                                        src="/assets/promo-background/img/rect2.svg"
                                        alt=""
                                        className="img-right img-fluid"
                                    />
                                </div>
                                <div className="content content-text">
                                    <div className="title mb-0">
                                        HOW DOES THE
                                    </div>
                                    <div className="title">AUCTION WORK?</div>
                                    <div className="subtitle">
                                        The auction lasts for 24 hours, during
                                        which, you can place your bid for the
                                        NFTs. You can make the payment using
                                        either <b>credit/debit cards</b>{" " }
                                        or {" " }
                                        <b>cryptocurrency.</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row bid-step-details justify-content-center align-items-center mt-0 mb-5">
                            <div className="col-12 col-md-6 col-xl-5 pr-xl-5">
                                <div className="content content-text align-items-md-end">
                                    <div className="title mb-0">
                                        WHEN IS THE
                                    </div>
                                    <div className="title  mb-0">AUCTION?</div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 col-xl-5  pl-xl-5 mt-4 mt-md-0 px-4 px-md-0">
                                {/* <div className="heading-container">
                                    <div className="heading-xl">
                                    2<sup>nd</sup> and 3<sup>rd</sup> of April
                                    </div>
                                    <div className="heading-xl">
                                        2022
                                    </div>
                                </div> */}
                                <div className="heading-container">
                                    <div className="heading-xl">
                                        Date pending. Stay tuned
                                    </div>                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="container mb-5">
                    <div className="text-center heading-container">
                        <div className="heading-lg letter-space-1 font-weight-bold">
                            Register Now
                        </div>
                        <div>
                            Please use your working email address so we can
                            connect you with the Hyperglade
                        </div>
                    </div>
                    <div className="form-row justify-content-center align-items-center ">
                        <div className="col-xl-6 col-xxl-5">
                            <div className="register-form-container mt-4 w-100">
                                <Form
                                    initialValues={registerFormData}
                                    validationSchema={registerSchema}
                                    onSubmit={onSubmitRegisterForm}
                                    enableReinitialize
                                    className="register-form d-md-flex "
                                >
                                    <div className="col-12 col-md-10 px-2">
                                        <TextField
                                            name="email"
                                            placeholder="Email"
                                        />
                                    </div>
                                    <div className="col-12 col-md-2 px-2 mt-3 mt-md-0 text-center text-md-none">
                                        <SubmitButton title="REGISTER" />
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>

                
                {/* <div className="bid-carousel-section container mt-5 pt-xl-5">
                    <div className="row">
                        <div className="col-xl-5 col-xxl-4">
                            <div className="title-text">
                                Most Popular and Bidded Art
                            </div>
                        </div>
                    </div>

                    <div className="mx-auto">
                        <div className="row">
                            <div className="col-xxl-12">
                                <Carousel />
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className="footer mt-5">
                    <div className="ribbon" />
                    <div className="footer-content">
                        <div className="org-info">
                            <div className="logo-container">
                                <div className="logo-wrapper">
                                    <img
                                        src="/assets/img/logo-light.svg"
                                        alt=""
                                        className="logo"
                                    />
                                </div>
                                <div className="logo-text">
                                    <div className="title">Hyperglade</div>
                                    <div className="subtitle">NFT Drop</div>
                                </div>
                            </div>
                            <div className="copyright-info">
                                © 2021 Hyperglade Auction. All rights reserved.
                            </div>
                            <div className="social-buttons">
                                <a
                                    href="https://twitter.com/hyperglade"
                                    target="_blank"
                                    className="social-button"
                                    type="button"
                                >
                                    <img
                                        src="/assets/icon/twitter.png"
                                        width="100%"
                                        alt=""
                                    />
                                </a>
                                <a
                                    href="https://www.instagram.com/hyper.glade"
                                    target="_blank"
                                    className="social-button"
                                    type="button"
                                >
                                    <img
                                        src="/assets/icon/instagram.png"
                                        width="100%"
                                        alt=""
                                    />
                                </a>
                                <a
                                    href="https://discord.gg/m8AsMPRX"
                                    target="_blank"
                                    className="social-button"
                                    type="button"
                                >
                                    <img
                                        src="/assets/icon/discord.png"
                                        width="100%"
                                        alt=""
                                    />
                                </a>
                                <a
                                    href="https://www.facebook.com/HyperGlade-103943078730431/"
                                    target="_blank"
                                    className="social-button"
                                    type="button"
                                >
                                    <img
                                        src="/assets/icon/facebook.png"
                                        width="100%"
                                        alt=""
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="more-info">
                            <div className="site-info">
                                <div className="title">HYPERGLADE</div>
                                <a href="https://hyperglade.com" className="link" target="_blank">
                                    Who we are
                                </a>
                            </div>
                            <div className="policy-info">
                                <div className="title">INFORMATION</div>
                                <a href="mailto: support@hyperglade.com" className="link" target="_blank">
                                    support@hyperglade.com
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PromoBackground>
    );
}

export default PromotionPage;
