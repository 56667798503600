export const ACCESS_TOKEN_NAME = "adminToken";
export const REFRESH_TOKEN_NAME = "refreshToken";

export const getFirebaseConfig = () => {
    console.log("[Firebase Config] ", process.env.REACT_APP_STAGE);

    return {
        apiKey: "AIzaSyBO1SGuG6j0YLjh3_skTCq6N2bFRRYTdYo",
        authDomain: "hyperglade.firebaseapp.com",
        projectId: "hyperglade",
        storageBucket: "hyperglade.appspot.com",
        messagingSenderId: "1064647347336",
        appId: "1:1064647347336:web:0fd5c56ac01bcc00f7233d",
        measurementId: "G-MZ7N5RRF5L"
      };
}

export const FIREBASE_WAITLIST_COLLECTION = 'waitlist';